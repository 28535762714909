body {
    background-color: #3b4252;
}

/* Add this to your CSS file */
.App {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    margin: 0; /* Remove default margin */
  }
  
  .sensors {
    width: 90%; /* Adjust as needed */
    max-width: 1000px; 
    /* Centering the contents of the sensors div */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  